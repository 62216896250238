/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, useContext, useEffect, useRef, useState } from "react";
import ShowMoreText from "react-show-more-text";
import AddToCart from "./AddToCart";
import {
  formatNumberToTwoDigitDecimal,
  formatUSPhoneNumber,
} from "@/utils/formatter";
import { ItemModel } from "@/models/item.model";
import { LocationModel } from "@/models/location.model";
import { CategoryModel } from "@/models/category.model";
import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { change24HrsFormatTo12Hrs } from "@/utils/dates";
import Image from "next/image";
import { ProgressSpinner } from "primereact/progressspinner";
import useIsMobile from "@/hooks/useIsMobile";

export default function MenuCard({
  item,
  location,
  categories,
}: {
  item: ItemModel;
  location: LocationModel;
  categories: CategoryModel[];
}) {
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);
  const [categoryName, setCategoryName] = useState("");
  const op = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setCategoryName(
      categories.find((category) => category._id === item.categoryId)?.name ??
        ""
    );
  }, [item, categories]);

  // useEffect(() => {
  //   setIsImageLoaded(false);
  // }, [item]);

  const getButton = () => {
    return item.settings?.oosViewOnlyEnabled ? (
      <Button
        className="btn btn-salmon-background"
        style={{ height: 34, width: "100px", fontSize: "10px" }}
        label="In-Store Only"
        disabled
      />
    ) : !metadataState.currentSlot ? (
      <Button
        className="btn btn-salmon-background"
        style={{ height: 34, width: "150px", fontSize: "10px", opacity: 0.7 }}
        label="Not Accepting Orders"
        onClick={(e) => (op as any).current.toggle(e)}
      />
    ) : metadataState.currentSlot.excludedTags.some((element) =>
        item.tags.includes(element)
      ) ? (
      <Button
        className="btn btn-salmon-background"
        style={{ height: 34, width: "150px", fontSize: "10px", opacity: 0.7 }}
        label="Not Available"
        disabled
      />
    ) : item.settings.oosOutOfStockEnabled ? (
      <Button
        className="btn btn-salmon-background"
        style={{ height: 34, width: "150px", fontSize: "10px", opacity: 0.7 }}
        label="Out of stock"
        disabled
      />
    ) : (
      <AddToCart item={item} />
    );
  };

  const mobileView = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="menu-6-txt rel" style={{ whiteSpace: "normal" }}>
              <div className="row d-flex align-items-center">
                {location?.oosConfig?.orderOnline?.showImages ? (
                  <div
                    className="menu-6-img rel col-3"
                    style={{ borderRadius: "6px" }}
                  >
                    {/* Image */}
                    {!isImageLoaded && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "40px" }}
                      >
                        <ProgressSpinner
                          strokeWidth="6"
                          style={{ width: "50px", height: "50px" }}
                          animationDuration=".5s"
                        />
                      </div>
                    )}
                    <Image
                      height={80}
                      width={0}
                      src={
                        item?.imageUrl
                          ? item.imageUrl
                          : "https://s3.us-east-2.amazonaws.com/stage.kuberpos.com/coming-soon-oos.jpeg"
                      }
                      style={{
                        width: "100%",
                        display: isImageLoaded ? "block" : "none",
                        marginBottom: "10px",
                        borderRadius: "6px",
                      }}
                      alt={`${item.name} image`}
                      priority
                      onLoadingComplete={() => setIsImageLoaded(true)}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={
                    location?.oosConfig?.orderOnline?.showImages
                      ? "col-9"
                      : "col-12"
                  }
                >
                  <h4 className="h6-sm coffee-color mt-0 overflow-ellipsis mb-0">
                    {item?.name}
                  </h4>
                  <p className="grey-color" style={{ marginBottom: "7px" }}>
                    {categoryName}
                  </p>
                </div>
              </div>
              {metadataState?.location?.oosConfig?.orderOnline
                ?.showDescription ? (
                <div className="menu-description">
                  <div className="black-color" style={{ fontSize: "14px" }}>
                    {item?.description}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-between align-items-start mt-3">
                <div className="menu-6-price bg-coffee">
                  <h6 className="h5-xs yellow-color mb-0 text-transform-none">
                    {getItemPricing()}
                  </h6>
                </div>

                {getButton()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const webView = () => {
    return (
      <>
        {/* IMAGE */}
        {location?.oosConfig?.orderOnline?.showImages ? (
          <div className="menu-6-img rel">
            <div className="hover-overlay">
              {/* Image */}
              {!isImageLoaded && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "342px" }}
                >
                  <ProgressSpinner
                    strokeWidth="6"
                    style={{ width: "50px", height: "50px" }}
                    animationDuration=".5s"
                  />
                </div>
              )}
              <div className="d-flex justify-content-center align-items-center">
                <Image
                  height={364}
                  width={0}
                  src={
                    item?.imageUrl
                      ? item.imageUrl
                      : "https://s3.us-east-2.amazonaws.com/stage.kuberpos.com/coming-soon-oos.jpeg"
                  }
                  style={{
                    width: "100%",
                    display: isImageLoaded ? "block" : "none",
                  }}
                  alt={`${item.name} image`}
                  priority
                  onLoadingComplete={() => setIsImageLoaded(true)}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* TEXT */}
        <div className="menu-6-txt rel">
          {/* Like Icon */}
          {/* <div className="like-ico ico-25">
              <Link href="#">
                <span className="flaticon-heart" />
              </Link>
            </div> */}
          {/* Title */}
          <h5
            className="h6-sm coffee-color mt-0 overflow-ellipsis"
            style={{ height: "54px" }}
          >
            {item?.name}
          </h5>
          <p className="grey-color">{categoryName}</p>
          {/* Description */}
          {metadataState?.location?.oosConfig?.orderOnline?.showDescription ? (
            <div
              className="menu-description"
              style={{ height: 100, overflow: "scroll" }}
            >
              <div className="black-color">
                <ShowMoreText
                  /* Default options */
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className="content-css"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {item?.description}
                </ShowMoreText>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Price */}
          <div className="d-flex justify-content-between align-items-start mt-4">
            <div className="menu-6-price bg-coffee">
              <h6
                className="h5-xs yellow-color mb-0 text-transform-none"
              >
                {getItemPricing()}
              </h6>
            </div>

            {getButton()}
          </div>
        </div>
      </>
    );
  };

  const getItemPricing = (): string => {
    if (item?.isVariablePrice) {
      return "Variable";
    } else if (item?.settings?.isPricedByWeight) {
      return `\$${formatNumberToTwoDigitDecimal(item.price)}/lb`;
    } else {
      return `\$${formatNumberToTwoDigitDecimal(item.price)}`;
    }
  };

  return (
    <>
      <div className="col-sm-6 col-lg-4 menu-card">
        <div
          className="menu-6-item bg-white"
          style={{
            height: "auto",
          }}
        >
          {isMobile ? mobileView() : webView()}
        </div>
      </div>
      <OverlayPanel ref={op}>
        <h6 style={{ color: "red" }}>
          Online ordering is not available during non-business hours.
        </h6>
        <p className="grey-color" style={{ fontSize: "14px" }}>
          Please check operating hours or call us at +1{" "}
          {formatUSPhoneNumber(location?.contactNumber)}
        </p>
        {metadataState.oosTimetable.timetable.map((timetable, index) => {
          return (
            <p className="mb-1" key={index}>
              <span
                style={{
                  color: !timetable?.status ? "red" : "",
                  fontWeight: "bold",
                }}
              >
                {timetable?.weekDay}:{" "}
              </span>
              {timetable?.slots.map((slot, timeSlotIndex) => {
                return (
                  <span style={{ fontSize: 16 }} key={timeSlotIndex}>
                    {change24HrsFormatTo12Hrs(slot.startTime)} -{" "}
                    {change24HrsFormatTo12Hrs(slot.endTime)}
                    {timeSlotIndex === timetable.slots.length - 1 ? null : ", "}
                  </span>
                );
              })}
            </p>
          );
        })}
      </OverlayPanel>
    </>
  );
}
