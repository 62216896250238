import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import ToastService from "@/services/toast.service";
import { Dispatch, useContext } from "react";

export default function Quantity({
  addedQuantity,
  onAddClick,
  onSubtractClick,
  height = "36px",
  width = "36px",
  fontSize = "18px",
  isAddDisabled = false,
}: {
  addedQuantity: number;
  onAddClick: Function;
  onSubtractClick: Function;
  height?: string;
  width?: string;
  fontSize?: string;
  isAddDisabled?: boolean;
}) {
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);

  const restaurantIsClosed = () => {
    ToastService.showFailure('Sorry, you can only modify items during working hours.');
  }

  return (
    <div className="d-flex gap-3">
      <div
        onClick={() => (metadataState.currentSlot ? onSubtractClick() : restaurantIsClosed())}
        style={{ height: height, width: width }}
        className={`quantity-actions ${
          metadataState.currentSlot ? "" : "restaurant-closed"
        }`}
      >
        -
      </div>
      <div style={{ fontSize }} className="quantity d-flex align-items-center">
        {addedQuantity}
      </div>
      {isAddDisabled ? (
        <div
          style={{ height: height, width: width, opacity: 0.7 }}
          className={"quantity-actions"}
        >
          +
        </div>
      ) : (
        <div
          onClick={() => (metadataState.currentSlot ? onAddClick() : restaurantIsClosed())}
          style={{ height: height, width: width }}
          className={`quantity-actions ${
            metadataState.currentSlot ? "" : "restaurant-closed"
          }`}
        >
          +
        </div>
      )}
    </div>
  );
}
