import Quantity from "@/components/quantity";
import { ActionType } from "@/context/metadata.context";
import {
  OrderContext,
  OrderStateModel,
  decrementItemCountById,
  incrementItemCountById,
} from "@/context/order.context";
import { ItemModel } from "@/models/item.model";
import { IAddon } from "@/models/order.model";
import { Dialog } from "primereact/dialog";
import { Dispatch, useContext } from "react";

export default function RemoveAddonModal({
  isOpen,
  item,
  onClose,
}: {
  isOpen: boolean;
  item: ItemModel;
  onClose: Function;
}) {
  const { state: orderState, dispatch } = useContext<{
    state: OrderStateModel;
    dispatch: Dispatch<ActionType>;
  }>(OrderContext);

  const getAddons = (addons: IAddon[]): string => {
    let response = "";

    for (var i = 0; i < addons.length; i++) {
      var addon = addons[i];
      response += `${addon.name}${
        addon.quantity > 1 ? " (" + addon.quantity + ")" + " " : ""
      }, `;
    }

    return response.substring(0, response.length - 2);
  };

  return (
    <Dialog
      visible={isOpen}
      className="kuber-dialog"
      onHide={() => onClose(false)}
      draggable={false}
      style={{ width: "70%" }}
      blockScroll
    >
      <div className="m-0">
        <div className="cart-table">
          <table id="myTable">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {orderState.order.orders[0].items.filter(
                (ele) => ele.itemId === item._id
              ).length > 0 ? (
                orderState.order.orders[0].items
                  .filter((ele) => ele.itemId === item._id)
                  .map((item) => {
                    return (
                      <tr key={item.id}>
                        <td data-label="Product" className="product-name">
                          {/* Preview */}
                          {/* <div className="cart-product-img"><img src="/images/menu/burger-07.png" alt="cart-preview" /></div> */}
                          {/* Description */}
                          <div className="cart-product-desc">
                            <h5 className="h5-sm">{item.name}</h5>
                            <p className="p-sm">
                              {item.addons.length === 0
                                ? "No addons found"
                                : getAddons(item.addons)}
                            </p>
                            {item.notes && (
                              <p style={{ color: "#e3000e" }} className="p-sm">
                                Notes - {item.notes}
                              </p>
                            )}
                          </div>
                        </td>
                        <td data-label="Price" className="product-price">
                          <h5 className="h5-md">
                            ${item.price}
                            <span style={{ fontSize: "16px" }}>
                              {item.weight !== "0.00" && `/${item.weight}lb`}
                            </span>
                          </h5>
                        </td>
                        <td data-label="Quantity" className="product-qty">
                          <Quantity
                            addedQuantity={item.quantity}
                            onAddClick={() =>
                              dispatch(incrementItemCountById(item.id))
                            }
                            onSubtractClick={() =>
                              dispatch(decrementItemCountById(item.id))
                            }
                          />
                        </td>
                        <td data-label="Total" className="product-price-total">
                          <h5 className="h5-md">
                            ${item.billingDetails.subtotal}
                          </h5>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td>No Items added to the cart</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
}
