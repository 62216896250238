import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import {
  addItem,
  OrderContext,
  OrderStateModel,
} from "@/context/order.context";
import { ItemModel } from "@/models/item.model";
import { IItem, initializeItemBillingDetails } from "@/models/order.model";
import ToastService from "@/services/toast.service";
import { formatNumberToTwoDigitDecimal } from "@/utils/formatter";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dispatch, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function PricedByWeightModal({
  isOpen,
  item,
  onClose,
}: {
  isOpen: boolean;
  item: ItemModel;
  onClose: Function;
}) {
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);
  const { dispatch } = useContext<{
    state: OrderStateModel;
    dispatch: Dispatch<ActionType>;
  }>(OrderContext);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [weight, setWeight] = useState("");
  const [total, setTotal] = useState("0.00");

  useEffect(() => {
    if (!weight) {
      setTotal('0.00');
    } else {
      setTotal(formatNumberToTwoDigitDecimal(+item.price * +weight));
    }
  }, [weight]);

  const addToCart = () => {
    if (!weight || +weight === 0 || isNaN(+weight)) {
      ToastService.showFailure("Please enter valid weight");
      return;
    }

    const category = metadataState.categories.find(
      (ele) => ele._id === item.categoryId
    );

    const itemData: IItem = {
      id: uuidv4(),
      addons: [],
      addonsTotal: "0.00",
      categoryId: item.categoryId,
      categoryName: category?.name ?? "",
      itemId: item._id,
      name: item.name,
      itemName: item.name,
      notes: specialInstructions,
      quantity: 1,
      price: total,
      taxItems: [],
      taxGroups: [],
      sentToKitchenQuantity: 0,
      billingDetails: initializeItemBillingDetails(),
      isPricedByWeight: true,
      weight,
    };
    dispatch(
      addItem(itemData)
    );
    onClose();
  };

  return (
    <Dialog
      visible={isOpen}
      className="kuber-dialog"
      onHide={() => onClose(false)}
      draggable={false}
      blockScroll
      footer={
        <div className="d-flex justify-content-between">
          <h4>Total: ${total}</h4>
          <div
            className="add-to-cart bg-yellow ico-10 d-flex align-items-center ms-2"
            style={{ position: "relative" }}
            onClick={() => addToCart()}
          >
            <span className="flaticon-shopping-bag mr-2" /> Add to Cart
          </div>
        </div>
      }
    >
      <div className="m-0 mt-2">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="h2-lg" style={{ color: "#642f21" }}>
            {item.name}
          </h4>
          {!item.isVariablePrice && (
            <h5 className="h4-xl yellow-color">
              ${formatNumberToTwoDigitDecimal(item?.price)}/lb
            </h5>
          )}
        </div>

        <InputText
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          placeholder="Enter weight in lb"
          className="mb-2"
        />

        {item.settings.oosOrderNotesEnabled && (
          <InputTextarea
            value={specialInstructions}
            onChange={(e) => setSpecialInstructions(e.target.value)}
            rows={3}
            placeholder="Enter Special Instructions"
          />
        )}
      </div>
    </Dialog>
  );
}
