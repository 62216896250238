import Quantity from "@/components/quantity";
import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import {
  ItemAddonGroupModel,
  ItemModel,
  ItemVariablePriceModel,
} from "@/models/item.model";
import { IItem, initializeItemBillingDetails } from "@/models/order.model";
import { formatNumberToTwoDigitDecimal } from "@/utils/formatter";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AddonGroupModel } from "@/models/addonGroup.model";
import { Button } from "primereact/button";
import { AddonModel } from "@/models/addon.model";
import {
  OrderContext,
  OrderStateModel,
  addItem,
} from "@/context/order.context";
import ToastService from "@/services/toast.service";
import { NavDropdown } from "react-bootstrap";
import { InputTextarea } from "primereact/inputtextarea";

export default function AddonModal({
  isOpen,
  item,
  onClose,
}: {
  isOpen: boolean;
  item: ItemModel;
  onClose: Function;
}) {
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);
  const { dispatch } = useContext<{
    state: OrderStateModel;
    dispatch: Dispatch<ActionType>;
  }>(OrderContext);
  const [itemData, setItemData] = useState<IItem>({
    id: uuidv4(),
    addons: [],
    addonsTotal: "0.00",
    categoryId: item.categoryId,
    categoryName: "",
    itemId: item._id,
    name: item.name,
    itemName: item.name,
    notes: "",
    quantity: 1,
    price: item.price,
    taxItems: [],
    taxGroups: [],
    sentToKitchenQuantity: 0,
    billingDetails: initializeItemBillingDetails(),
    isPricedByWeight: false,
    weight: "0.00",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [addonGroups, setAddonGroups] = useState<ItemAddonGroupModel[]>([]);
  const [selectedVariablePrice, setSelectedVariablePrice] =
    useState<ItemVariablePriceModel>();
  const [specialInstructions, setSpecialInstructions] = useState("");

  const updateAddonGroups = useCallback(
    (itemAddonGroups: ItemAddonGroupModel[]) => {
      const validAddonGroups = itemAddonGroups.filter((addonGroup) =>
        metadataState.addonGroups.some(
          (metadataGroup) => metadataGroup._id === addonGroup.addonGroupId
        )
      );

      setAddonGroups(validAddonGroups);
    },
    [metadataState.addonGroups]
  );

  useEffect(() => {
    const initializeAddonGroups = () => {
      const addonGroups =
        item.isVariablePrice && item.variablePrices.length > 0
          ? item.variablePrices[0].addonGroups
          : item.addonGroups;

      updateAddonGroups(addonGroups);

      if (item.isVariablePrice && item.variablePrices.length > 0) {
        setSelectedVariablePrice(item.variablePrices[0]);
      }
    };

    initializeAddonGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (selectedVariablePrice) {
      const itemDataClone = structuredClone(itemData);
      itemDataClone.addons = [];
      setItemData(itemDataClone);

      updateAddonGroups(selectedVariablePrice?.addonGroups ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariablePrice]);

  const getAddonGroup = (addonGroupId: string): AddonGroupModel | undefined => {
    return metadataState.addonGroups.find(
      (addonGroup) => addonGroup._id === addonGroupId
    );
  };

  const addAddon = (
    addonGroup: ItemAddonGroupModel,
    addon: AddonModel,
    variablePriceId: string
  ) => {
    var clonedItemData = structuredClone(itemData);

    let price = addon.price;
    let name = addon.name;

    if (variablePriceId) {
      var variablePrice = addon.variablePrices.find(
        (element) => element._id == variablePriceId
      );
      if (variablePrice) {
        price = variablePrice?.price ?? "0.00";
        name = `${addon.name} (${variablePrice.name})`;
      }
    }

    const addonAddedIndex = clonedItemData.addons.findIndex(
      (addedAddon) =>
        addedAddon.addonId === addon._id &&
        addedAddon.addonGroupId === addonGroup.addonGroupId
    );
    if (addonAddedIndex > -1) {
      clonedItemData.addons[addonAddedIndex].price = price;
      clonedItemData.addons[addonAddedIndex].name = name;
      clonedItemData.addons[addonAddedIndex].variablePriceId = variablePriceId;
    } else {
      clonedItemData.addons = [
        ...itemData.addons,
        {
          addonId: addon._id,
          addonGroupId: addonGroup.addonGroupId,
          variablePriceId,
          price,
          quantity: 1,
          discount: "0.00",
          name,
          addonName: addon.name,
          subtotal: addon.price,
          total: addon.price,
        },
      ];
    }
    calculateTotal(clonedItemData);
  };

  const incrementAddon = (
    addonGroup: ItemAddonGroupModel,
    addon: AddonModel
  ) => {
    const itemDataClone = structuredClone(itemData);

    const addonIndex = itemDataClone.addons.findIndex(
      (ele) =>
        ele.addonGroupId === addonGroup.addonGroupId &&
        ele.addonId === addon._id
    );
    if (addonIndex > -1) {
      itemDataClone.addons[addonIndex].quantity += 1;
    }
    calculateTotal(itemDataClone);
  };

  const decrementAddon = (
    addonGroup: ItemAddonGroupModel,
    addon: AddonModel
  ) => {
    const itemDataClone = structuredClone(itemData);

    const addonIndex = itemDataClone.addons.findIndex(
      (ele) =>
        ele.addonGroupId === addonGroup.addonGroupId &&
        ele.addonId === addon._id
    );
    if (addonIndex > -1) {
      if (itemDataClone.addons[addonIndex].quantity === 1) {
        itemDataClone.addons.splice(addonIndex, 1);
      } else {
        itemDataClone.addons[addonIndex].quantity -= 1;
      }
    }
    calculateTotal(itemDataClone);
  };

  const calculateTotal = (itemDataClone: IItem) => {
    for (var i = 0; i < itemDataClone.addons.length; i++) {
      var addon = itemDataClone.addons[i];

      const total = +addon.price * addon.quantity;
      addon.total = formatNumberToTwoDigitDecimal(total.toString());
    }

    setItemData(itemDataClone);
  };

  const isDisabled = (addonGroup: ItemAddonGroupModel): boolean => {
    let selectedAddonsQuantity = 0;
    for (let index = 0; index < itemData.addons.length; index++) {
      const element = itemData.addons[index];
      if (element.addonGroupId === addonGroup.addonGroupId) {
        selectedAddonsQuantity += element.quantity;
      }
    }
    return selectedAddonsQuantity == addonGroup.maxAddonCount;
  };

  const isCartValid = (): boolean => {
    let isValid = true;
    setFormSubmitted(true);

    for (let index = 0; index < addonGroups.length; index++) {
      const addonGroup = addonGroups[index];
      let addonAddedCount = 0;

      itemData.addons.forEach((addon) => {
        if (addonGroup.addonGroupId === addon.addonGroupId) {
          addonAddedCount += addon.quantity;
        }
      });

      if (addonAddedCount < addonGroup.minAddonCount) {
        isValid = false;
        document.getElementById(addonGroup.addonGroupId)?.scrollIntoView({
          behavior: "smooth",
        });
        ToastService.showFailure("Select all the mandatory addons");
        break;
      }
    }

    return isValid;
  };

  const addToCart = () => {
    if (isCartValid()) {
      const category = metadataState.categories.find(
        (ele) => ele._id === item.categoryId
      );
      dispatch(
        addItem({
          ...itemData,
          name: item.isVariablePrice
            ? `${itemData.name} (${selectedVariablePrice?.name})`
            : itemData.name,
          categoryName: category?.name ?? "",
          price:
            item.isVariablePrice && selectedVariablePrice?.itemPrice
              ? selectedVariablePrice.itemPrice
              : item.price,
          notes: specialInstructions,
        })
      );
      onClose();
    }
  };

  const variablePrices = () => {
    if (!item.isVariablePrice) return <></>;

    return (
      <div className="d-flex mb-2">
        {item.variablePrices.map((variablePrice) => (
          <div
            className={`me-2 d-flex justify-content-center flex-column variable-price ${
              selectedVariablePrice?._id === variablePrice._id
                ? "variable-price-selected"
                : ""
            }`}
            onClick={() =>
              selectedVariablePrice?._id !== variablePrice._id &&
              setSelectedVariablePrice(variablePrice)
            }
            key={variablePrice._id}
          >
            <div className="d-flex">{variablePrice.name}</div>
            <div className="d-flex">${variablePrice.itemPrice}</div>
          </div>
        ))}
      </div>
    );
  };

  const addonVariablePrices = (
    addonGroup: ItemAddonGroupModel,
    addon: AddonModel
  ) => {
    const addedAddonIndex = itemData.addons.findIndex(
      (addedAddon) =>
        addedAddon.addonId === addon._id &&
        addedAddon.addonGroupId === addonGroup.addonGroupId
    );

    let title = "";

    if (addedAddonIndex > -1) {
      const variablePriceData = addon.variablePrices.find(
        (variablePrice) =>
          variablePrice._id === itemData.addons[addedAddonIndex].variablePriceId
      );
      if (variablePriceData) {
        title = `${variablePriceData.name} - \$${variablePriceData.price}`;
      }
    } else {
      title = `${addon.variablePrices[0].name} - \$${addon.variablePrices[0].price}`;
    }
    return (
      <div className="d-flex mb-2 mt-1" id="addon-variable-price-ddl">
        <NavDropdown
          title={title}
          disabled={
            isDisabled(addonGroup) &&
            itemData.addons.filter(
              (addedAddon) =>
                addedAddon.addonGroupId === addonGroup.addonGroupId &&
                addedAddon.addonId === addon._id
            ).length === 0
          }
        >
          {addon.variablePrices.map((variablePrice) => (
            <NavDropdown.Item
              onClick={() => {
                addAddon(addonGroup, addon, variablePrice._id);
              }}
              key={variablePrice._id}
            >
              {variablePrice.name} - ${variablePrice.price}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </div>
    );
  };

  return (
    <Dialog
      visible={isOpen}
      className="kuber-dialog"
      onHide={() => onClose(false)}
      draggable={false}
      blockScroll
      footer={
        <div className="d-flex justify-content-end">
          <div
            className="add-to-cart bg-yellow ico-10 d-flex align-items-center ms-2"
            style={{ position: "relative" }}
            onClick={() => addToCart()}
          >
            <span className="flaticon-shopping-bag mr-2" /> Add to Cart
          </div>
        </div>
      }
    >
      <div className="m-0 mt-2">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="h2-lg" style={{ color: "#642f21" }}>
            {item.name}
          </h4>
          {!item.isVariablePrice && (
            <h5 className="h4-xl yellow-color">
              ${formatNumberToTwoDigitDecimal(item?.price)}
            </h5>
          )}
        </div>

        {variablePrices()}

        <div className="addons">
          {addonGroups.map((addonGroup) => {
            return (
              <Panel
                className={`mb-3 ${
                  formSubmitted &&
                  addonGroup.minAddonCount !== 0 &&
                  !isDisabled(addonGroup)
                    ? "p-panel-header-red"
                    : ""
                }`}
                id={addonGroup.addonGroupId}
                header={getAddonGroup(addonGroup.addonGroupId)?.name}
                key={addonGroup.addonGroupId}
                toggleable
              >
                <div className="d-flex justify-content-end">
                  <h6 className="m-0">
                    Min-{addonGroup.minAddonCount} Max-
                    {addonGroup.maxAddonCount}
                  </h6>
                </div>

                <div className="mt-3"></div>
                {getAddonGroup(addonGroup.addonGroupId)?.addons.map(
                  (addonId, index) => {
                    const addonData = metadataState.addons.find(
                      (addon) => addon._id === addonId
                    );
                    if (!addonData) {
                      return <></>;
                    }
                    const itemAddonData = itemData.addons.find(
                      (addon) =>
                        addon.addonId === addonData._id &&
                        addon.addonGroupId === addonGroup.addonGroupId
                    );
                    return (
                      <div
                        className="d-flex mt-1 justify-content-between"
                        key={`${addonGroup.addonGroupId}${index}`}
                      >
                        <div>
                          <h6 className="mb-0">{addonData?.name}</h6>
                          {addonData.isVariablePrice ? (
                            addonVariablePrices(addonGroup, addonData)
                          ) : (
                            <p style={{ fontSize: "12px" }}>
                              ${addonData.price}
                            </p>
                          )}
                        </div>
                        {itemAddonData ? (
                          <div style={{ height: "22px" }}>
                            <Quantity
                              addedQuantity={itemAddonData.quantity}
                              onAddClick={() =>
                                incrementAddon(addonGroup, addonData)
                              }
                              onSubtractClick={() =>
                                decrementAddon(addonGroup, addonData)
                              }
                              height="22px"
                              width="22px"
                              fontSize="14px"
                              isAddDisabled={isDisabled(addonGroup)}
                            />
                          </div>
                        ) : (
                          <Button
                            className="btn btn-salmon-background btn-salmon-yellow"
                            style={{
                              height: 32,
                              fontSize: "11px",
                              width: "45px",
                            }}
                            label="Add"
                            onClick={() =>
                              addAddon(
                                addonGroup,
                                addonData,
                                addonData.isVariablePrice
                                  ? addonData.variablePrices[0]._id
                                  : ""
                              )
                            }
                            disabled={isDisabled(addonGroup)}
                          />
                        )}
                      </div>
                    );
                  }
                )}
              </Panel>
            );
          })}
        </div>

        {item.settings.oosOrderNotesEnabled && (
          <InputTextarea
            value={specialInstructions}
            onChange={(e) => setSpecialInstructions(e.target.value)}
            rows={3}
            placeholder="Enter Special Instructions"
          />
        )}
      </div>
    </Dialog>
  );
}
