/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, useContext, useEffect, useState } from "react";
import {
  ActionType,
  MetadataContext,
  MetadataStateModel,
} from "@/context/metadata.context";
import {
  OrderContext,
  OrderStateModel,
  addItem,
  decrementItemCount,
  incrementItemCount,
} from "@/context/order.context";
import Quantity from "@/components/quantity";
import { ItemAddonGroupModel, ItemModel } from "@/models/item.model";
import { IItem, initializeItemBillingDetails } from "@/models/order.model";
import { v4 as uuidv4 } from "uuid";
import AddonModal from "./AddonModal";
import RemoveAddonModal from "./RemoveAddonModal";
import PricedByWeightModal from "./PricedByWeightModal";

export default function AddToCart({ item }: { item: ItemModel }) {
  const [showAddonsDialog, setShowAddonsDialog] = useState(false);
  const [showPricedByWeightDialog, setShowPricedByWeightDialog] =
    useState(false);
  const [showRemoveAddonsDialog, setShowRemoveAddonsDialog] = useState(false);
  const [addonGroups, setAddonGroups] = useState<ItemAddonGroupModel[]>([]);
  const { state: orderState, dispatch } = useContext<{
    state: OrderStateModel;
    dispatch: Dispatch<ActionType>;
  }>(OrderContext);
  const { state: metadataState } = useContext<{
    state: MetadataStateModel;
    dispatch: Dispatch<ActionType>;
  }>(MetadataContext);
  const [addedQuantity, setAddedQuantity] = useState<number>(0);

  useEffect(() => {
    setAddonGroupsData();
    calculateAddedQuantity();
  }, [item]);

  useEffect(() => {
    calculateAddedQuantity();
  }, [orderState.order]);

  const setAddonGroupsData = () => {
    const response: ItemAddonGroupModel[] = [];

    for (var i = 0; i < item.addonGroups.length; i++) {
      var element = metadataState.addonGroups.find(
        (element) => element._id == item.addonGroups[i].addonGroupId
      );

      if (element && !response.includes(item.addonGroups[i])) {
        response.push(item.addonGroups[i]);
      }
    }
    setAddonGroups(response);
  };

  const calculateAddedQuantity = () => {
    let quantity = 0;
    for (
      let index = 0;
      index < orderState.order.orders[0].items.length;
      index++
    ) {
      const element = orderState.order.orders[0].items[index];
      if (element.itemId === item._id) {
        quantity += element.quantity;
      }
    }
    setAddedQuantity(quantity);
  };

  const addItemToCart = () => {
    const category = metadataState.categories.find(
      (ele) => ele._id === item.categoryId
    );

    const itemData: IItem = {
      id: uuidv4(),
      addons: [],
      addonsTotal: "0.00",
      categoryId: item.categoryId,
      categoryName: category?.name ?? "",
      itemId: item._id,
      name: item.name,
      itemName: item.name,
      notes: "",
      quantity: 1,
      price: item.price,
      taxItems: [],
      taxGroups: [],
      sentToKitchenQuantity: 0,
      billingDetails: initializeItemBillingDetails(),
      isPricedByWeight: false,
      weight: '0.00'
    };
    dispatch(addItem(itemData));
  };

  return (
    <>
      {addedQuantity === 0 ? (
        <div
          onClick={() =>
            item.settings?.isPricedByWeight
              ? setShowPricedByWeightDialog(true)
              : addonGroups.length > 0 && item.settings.oosCustomizableEnabled
              ? setShowAddonsDialog(true)
              : addItemToCart()
          }
          className="add-to-cart bg-yellow ico-10"
        >
          <span className="flaticon-shopping-bag mr-1" /> Add to Cart
        </div>
      ) : (
        <Quantity
          addedQuantity={addedQuantity}
          onAddClick={() =>
            item.settings?.isPricedByWeight
              ? setShowPricedByWeightDialog(true)
              : addonGroups.length > 0 && item.settings.oosCustomizableEnabled
              ? setShowAddonsDialog(true)
              : dispatch(incrementItemCount(item._id))
          }
          onSubtractClick={() =>
            item.settings?.isPricedByWeight
              ? setShowRemoveAddonsDialog(true)
              : addonGroups.length > 0 && item.settings.oosCustomizableEnabled
              ? setShowRemoveAddonsDialog(true)
              : dispatch(decrementItemCount(item._id))
          }
        ></Quantity>
      )}

      {showPricedByWeightDialog ? (
        <PricedByWeightModal
          isOpen={showPricedByWeightDialog}
          item={item}
          onClose={() => setShowPricedByWeightDialog(false)}
        />
      ) : (
        <></>
      )}

      {showAddonsDialog ? (
        <AddonModal
          isOpen={showAddonsDialog}
          item={item}
          onClose={() => setShowAddonsDialog(false)}
        />
      ) : (
        <></>
      )}

      {showRemoveAddonsDialog ? (
        <RemoveAddonModal
          isOpen={showRemoveAddonsDialog}
          item={item}
          onClose={() => setShowRemoveAddonsDialog(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
}
